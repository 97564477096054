import * as React from 'react';
import { useState, type InputHTMLAttributes, type LegacyRef } from 'react';
import clsx from 'clsx';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useField } from 'remix-validated-form';

import { Label } from '~/src/ui/primatives';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  form?: string;
  blurred?: boolean;
  required?: boolean;
  canTogglePassword?: boolean;
}

function BaseInput(
  { type, name, label, form, blurred, required, canTogglePassword, ...rest }: InputProps,
  ref: LegacyRef<HTMLInputElement>,
) {
  const [displayPassword, setDisplayPassword] = useState(false);

  const { error, getInputProps } = useField(name, {
    formId: form,
    validationBehavior: {
      // Set initial and whenTouched to "onSubmit" and it won't validate until submit
      initial: 'onSubmit',
      whenTouched: 'onSubmit',

      // If you want this behavior to change after submit, you can change that here
      whenSubmitted: 'onChange',
    },
  });

  return (
    <div className={rest.hidden ? 'hidden' : 'flex w-full flex-col gap-1'}>
      <div
        className={clsx(
          'group relative h-14 w-full rounded-xl border bg-white px-3 py-2 shadow-sm',
          'focus-within:ring-2 focus-within:ring-yonder-400 focus-within:ring-offset-2 hover:border-yonder-300 focus:outline-none',
          {
            'border-gray-300': !error,
            'border-red-500': error,
          },
        )}
      >
        <Label
          aria-labelledby={name}
          htmlFor={name}
          className={clsx('block select-none text-xs font-medium group-focus-within:text-yonder-600', {
            'text-red-500': error,
            'text-gray-900': !error,
          })}
        >
          {label}
          {required ? '*' : ''}
        </Label>

        <input
          {...getInputProps({ id: name })}
          {...rest}
          name={name}
          ref={ref}
          type={displayPassword && type === 'password' ? 'text' : type}
          className={clsx(
            'block w-full border-0 p-0 pr-8 text-gray-900',
            'focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0',
            {
              'blur focus:blur-none': blurred,
              'placeholder-red-500': error,
              'placeholder-gray-500': !error,
            },
          )}
        />

        {canTogglePassword && type === 'password' && (
          <button
            className='absolute right-4 top-1/2 mt-0.5'
            type='button'
            onClick={() => setDisplayPassword(!displayPassword)}
          >
            {displayPassword ? <FiEyeOff /> : <FiEye />}
          </button>
        )}
      </div>

      <p className='input-error-message ml-2'>{error || null}</p>
    </div>
  );
}

export const FormInput = React.forwardRef(BaseInput);
